<template>
  <div class="error404 text-center">
    <div class="container-fluid">
      <div class="row">
        <div class="col-md-4 mr-auto mt-5 text-md-left text-center">
          <router-link class="ml-md-5" to="/">
            <img
              alt="image-404"
              src="../../../assets/img/android-chrome-192x192.png"
              class="theme-logo"
            />
          </router-link>
        </div>
      </div>
    </div>
    <div class="container-fluid error-content">
      <div class="">
        <h1 class="error-number" style="color: #049a58">404</h1>
        <p class="mini-text">Ooops!</p>
        <p class="error-text mb-4 mt-1">
          The page you requested was not found!
        </p>
        <button
          @click.prevent="goBack()"
          style="
            background-image: linear-gradient(135deg, #049a58 0%, #000000 100%);
          "
          class="btn btn-success mt-5"
        >
          Go Back
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "NotFound",

  methods: {
    goBack() {
      this.$router.push({ path: "/" });
    },
  },
};
</script>

<style scoped>
.theme-logo {
  width: 62px;
  height: 62px;
}

body.error404 {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='100%25' height='100%25' viewBox='0 0 800 400'%3E%3Cdefs%3E%3CradialGradient id='a' cx='396' cy='281' r='514' gradientUnits='userSpaceOnUse'%3E%3Cstop offset='0' stop-color='%23e6e6e6'/%3E%3Cstop offset='1' stop-color='%23c2d5ff'/%3E%3C/radialGradient%3E%3ClinearGradient id='b' gradientUnits='userSpaceOnUse' x1='400' y1='148' x2='400' y2='333'%3E%3Cstop offset='0' stop-color='%231b55e2' stop-opacity='0'/%3E%3Cstop offset='1' stop-color='%231b55e2' stop-opacity='0.5'/%3E%3C/linearGradient%3E%3C/defs%3E%3Crect fill='url(%23a)' width='800' height='400'/%3E%3Cg fill-opacity='0.5'%3E%3Ccircle fill='url(%23b)' cx='267.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='532.5' cy='61' r='300'/%3E%3Ccircle fill='url(%23b)' cx='400' cy='30' r='300'/%3E%3C/g%3E%3C/svg%3E");
  background-attachment: fixed;
  background-size: cover;
  background-color: #ffffff;
}
body.error404 > .error-content {
  min-height: 80vh;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px;
}

.error404 .mini-text {
  font-size: 33px;
  font-weight: 700;
  margin-bottom: 0;
  color: #049a58;
}

.error404 .img-cartoon {
  width: 170px;
  height: 170px;
}

.error404 .error-number {
  font-size: 170px;
  color: #fff;
  font-weight: 600;
  margin-bottom: 5px;
  margin-top: 15px;
  text-shadow: 0px 5px 4px rgba(31, 45, 61, 0.101961);
}

.error404 .error-text {
  font-size: 18px;
  color: #3b3f5c;
  font-weight: 600;
}

.error404 a.btn {
  width: 134px;
  padding: 6px;
  font-size: 17px;
  background-image: linear-gradient(135deg, #049a58 0%, #5c1ac3 100%);
  border: none;
  letter-spacing: 2px;
}
.error404 a.btn:hover,
.error404 a.btn:not(:disabled):not(.disabled):active {
  background-image: linear-gradient(to right, #049a58 0%, #5c1ac3 100%);
}
</style>
